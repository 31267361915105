import { AxiosError } from 'axios'
import { MakeAxiosPaginatedResponse } from '../../../services/Service.types'
import {
    PriceOfferAddVariantRequest,
    PriceOfferFullData,
    PriceOfferShort
} from '../../../services/api/price-offer/PriceOffer.types'
import { createPriceOfferVariant } from '../../../services/api/price-offer/PriceOffer.service'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import useAxios from '../../useAxios'

const usePriceOfferCreateVariant = () => {
    const axiosInstance = useAxios()
    const queryClient = useQueryClient()

    return useMutation<PriceOfferFullData, AxiosError, { id: number; request: PriceOfferAddVariantRequest }>(
        ['createPriceOfferVariant'],
        ({ id, request }) => createPriceOfferVariant(axiosInstance, id, request),
        {
            onSuccess: data => {
                queryClient.setQueryData(['priceOffer', data.id], data)
                // @ts-ignore
                queryClient.setQueriesData<MakeAxiosPaginatedResponse<PriceOfferShort>>(['priceOffers'], prev => {
                    return {
                        ...prev,
                        data: prev.data.map(item => {
                            if (item.id === data.variantMain) {
                                return {
                                    ...item,
                                    variants: [
                                        ...item.variants,
                                        {
                                            step: data.step,
                                            id: data.id,
                                            name: data.name,
                                            orders: data.orders.map(order => {
                                                return {
                                                    id: order.id,
                                                    number: order.number,
                                                    status: order.status,
                                                    productName: order.productName,
                                                    customLabel: order.customLabel,
                                                    createdAt: order.createdAt,
                                                    price: order.price,
                                                    currency: order.currency
                                                }
                                            }),
                                            number: data.number,
                                            totalPrice: data.totalPrice,
                                            createdAt: data.createdAt,
                                            note: data.note,
                                            state: data.state,
                                            variant: data.variant,
                                            variants: []
                                        }
                                    ]
                                }
                            }
                            return item
                        })
                    }
                })
            }
        }
    )
}

export default usePriceOfferCreateVariant
