import { PriceOfferStepTwoDetail } from '../../../../services/api/price-offer/PriceOffer.types'
import { getPriceOfferStepData } from '../../../../services/api/price-offer/PriceOffer.service'
import React, { ReactNode, useState } from 'react'
import useAxios from '../../../../hooks/useAxios'
import usePriceOfferPdfRenderer from './PriceOfferPdfRenderer.hooks'

export type PriceOfferPdfDownloadProps = {
    children: (download: ({}) => void, isLoading: boolean) => ReactNode
    id: number
    everythingRequiredFilled: boolean
    isPriceOfferDetail?: boolean
    isForceUpdateToStepOne?: boolean
}

const PriceOfferPdfDownload = ({
    children,
    id,
    everythingRequiredFilled,
    isPriceOfferDetail = true,
    isForceUpdateToStepOne = false
}: PriceOfferPdfDownloadProps) => {
    const axiosInstance = useAxios()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { downloadPriceOfferPdf, isPriceOfferPdfLoading } = usePriceOfferPdfRenderer({ disableDownloadCache: true })

    const download = async ({ signal }) => {
        try {
            setIsLoading(true)
            const data = await getPriceOfferStepData(
                axiosInstance,
                id,
                { signal },
                isForceUpdateToStepOne,
                isPriceOfferDetail
            )

            if (!everythingRequiredFilled) {
                setIsLoading(false)
                return
            }
            await downloadPriceOfferPdf(data as PriceOfferStepTwoDetail)
            setIsLoading(false)
        } catch (e) {
            setIsLoading(false)
        }
    }

    return <>{children(download, isPriceOfferPdfLoading || isLoading)}</>
}

export default PriceOfferPdfDownload
